import React from 'react'
import styles from './MainPage.module.css'
import homeImagen from './home.jpg'
import ContactComponent from '../ContactComponent/ContactComponent'

const MainPage = () => {
  return (
    <div className={styles.mainDiv}>
      <div className={styles.texto}>
      <div className={styles.abogados}>
        Morra Salort Grimaut 
      </div>
      <div>
         Abogados 
      </div>
      <div>
        Estamos renovando nuestra página.
      </div>
      <div>
        We are updating our website.
      </div>
      <ContactComponent></ContactComponent>
      </div>
    </div>)
}

export default MainPage
