import React from 'react'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import styles from './ContactComponent.module.css'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';

const ContactComponent = () => {
  return (
    <div className={styles.mainContact}>
    <div className={styles.contactTitle}>Contactenos</div>
    <div className={styles.divIcons} >
       <a className={styles.links} target="_blank" href="https://walink.co/50b4d4"><WhatsAppIcon className={styles.contactIcon} sx={{ fontSize: "40px" }}/></a>
       <a className={styles.links} target="_blank" href="https://goo.gl/maps/165jrnrXfY5STkHX8"><LocationOnIcon className={styles.contactIcon} sx={{ fontSize: "40px" }}/></a>
       <a href="mailto:gsalort@msgabogados.com.ar" target="_blank" className={styles.links} ><EmailIcon className={styles.contactIcon} sx={{ fontSize: "40px" }}/></a> 
    </div>
    </div>
  )
}

export default ContactComponent